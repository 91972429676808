import wretch from 'wretch'
class ApiService {
  private static instance: ApiService
  private api = wretch(import.meta.env.DEV ? '/api' : 'https://vhostgame.club').headers({
    'Content-Type': 'application/json; charset=utf-8',
  })

  private constructor() {}

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService()
    }
    return ApiService.instance
  }

  public apiPhpRequest(payload: any): Promise<any> {
    return this.api.url('/api.php').post(payload).json()
  }

  public auth(userInfoPayload: any) {
    return this.apiPhpRequest({ ...userInfoPayload, method: 'auth' })
  }

  public getUserData() {
    return this.apiPhpRequest({ method: 'get_user_data' })
  }

  public tapMethod(count: number) {
    return this.apiPhpRequest({ method: 'tap', count })
  }

  public getTasks() {
    return this.apiPhpRequest({ method: 'get_tasks' })
  }

  public getCryptoWallets() {
    return this.apiPhpRequest({ method: 'get_cryptoexchanges_list' })
  }

  public claim(id: string) {
    return this.apiPhpRequest({ method: 'claim', id })
  }

  public saveWallet(cryptoexchange: string, wallet: string) {
    return this.apiPhpRequest({ method: 'save_wallet', wallet, cryptoexchange })
  }

  public getStoreCards() {
    return this.apiPhpRequest({ method: 'get_cards' })
  }

  public buyItem(id: any) {
    return this.apiPhpRequest({ method: 'buy_card', id })
  }

  public getUserFrensData() {
    return this.apiPhpRequest({ method: 'get_user_frens_data' })
  }

  public getTopUsersByBalance() {
    return this.apiPhpRequest({ method: 'get_top5_users_by_balance' })
  }

  public getTopUsersByRef() {
    return this.apiPhpRequest({ method: 'get_top5_users_by_referrals' })
  }

  public updateAuth() {
    return this.apiPhpRequest({ method: 'update' })
  }

  public startAutoFarm() {
    return this.apiPhpRequest({ method: 'autofarming_start' })
  }

  public closeInfoModal(notify: string) {
    return this.apiPhpRequest({ method: 'close_notify', notify })
  }

  public claimGame(result: string, lines: number, duration: number) {
    return this.apiPhpRequest({ method: 'game', result, lines, duration })
  }

  public changeLanguage(lang: string) {
    return this.apiPhpRequest({ method: 'change_language', lang })
  }
}

export default ApiService
